<template>
  <b-container class="validate-container">
    <b-row>
      <b-col class="d-flex justify-content-center mb-3">
        <b-spinner style="width: 3rem; height: 3rem; margin-top:15%;" label="Large Spinner" type="grow" variant="secondary"></b-spinner>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
  import Error from '@/handler/error'
  import Session from '@/handler/session'
  import serviceAPI from './services'

  export default {
    data () {
      return {

      }
    },
    mounted () {
      this.verificar()
    },
    methods: {
      verificar () {
        var result = serviceAPI.obtener()

        result.then((response) => {
          var data = response.data
          Session.setSessionSettings(data)

          if( data.status) {
            this.$router.push({ name: 'Login' })
          } else {
            this.$router.push({ name: 'Setup' })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        });
      }
    },
  }
</script>

<style>
  body {
    background-color: #fff;
  }
  .validate-container {
    margin-top: 10%;
  }
</style>
